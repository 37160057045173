<div class="app-admin-wrap layout-sidebar-large">
    <div>
        <app-header-sidebar-large></app-header-sidebar-large>
    </div>
    <div class="side-content-wrap">
        <app-sidebar-large></app-sidebar-large>
    </div>
    <div class="main-content-wrap d-flex flex-column" 
    [ngClass]="{'sidenav-open': navService.sidebarState.sidenavOpen}" 
    [perfectScrollbar]="{suppressScrollX: true}">
        <router-outlet></router-outlet>
        <div class="flex-grow-1"></div>
        <app-footer></app-footer>
        <div class="module-loader" *ngIf="moduleLoading">
            <div class="spinner spinner-bubble spinner-bubble-primary mr-3"></div>
        </div>
    </div>
</div>
<app-search *ngIf="searchService.searchOpen"></app-search>
<app-customizer></app-customizer>