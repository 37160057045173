<div class="main-header">
  <div class="logo">
    <img src="./assets/images/logo.png" alt="" />
  </div>

  <div class="menu-toggle" (click)="toggelSidebar()">
    <div></div>
    <div></div>
    <div></div>
  </div>

  <div class="d-flex align-items-center">
    <!-- Mega menu -->
    <div ngbDropdown  #accountDropdown="ngbDropdown" class="mega-menu d-none d-md-block">
      <button
        ngbDropdownToggle
        href="#"
        class="btn text-muted dropdown-toggle mr-3"
      >
        Account Menu
      </button>
      <div ngbDropdownMenu perfectScrollbar>
        <div class="row m-0">
          <div class="col-md-4 p-4 bg-img">
             <h4 class="title" *ngIf="userDetails?.role == 'user' || userDetails?.role == 'stationery'">
                Account Balance  <br> Tsh <span style="color:rgb(238, 238, 9)">{{ account ? account.amount_available  : 'N/A' }}</span>
              </h4>
              <span *ngIf="userDetails?.role == 'user' || userDetails?.role == 'stationery'" class="btn btn-lg btn-rounded btn-outline-warning pi pi-replay" (click)="getAccountDetails()">
              {{loading?'loading...':'Refresh Balance'}}
              </span>
              <br><br>
              <h4 *ngIf="account?.amount_held > 0" style="color:rgb(238, 238, 9)">
                Amount Held ({{ account?.amount_held || 'N/A' }})
              </h4>
              <p  *ngIf="userDetails?.role == 'user' || userDetails?.role == 'stationery'">You can deposit or withdraw in Payment page.</p>
              
            <button *ngIf="userDetails?.role == 'user' || userDetails?.role == 'stationery'" class="btn btn-lg btn-rounded btn-outline-warning" (click)="closeDropdown()" [routerLink]="['/pages/payments']">
              Payment Page
            </button>
          </div>
          <div class="col-md-4 p-4">
            <p
              class="text-primary text--cap border-bottom-primary d-inline-block"
            >
              Features
            </p>
            <div class="menu-icon-grid w-auto p-0">
                <a routerLink="/dashboard/v2"  (click)="closeDropdown()"><i class="i-Shop-4"></i> Home</a>
                <a routerLink="/pages/requests" (click)="closeDropdown()"><i class="i-Repeat2"></i> Requests</a>
                <a routerLink="/pages/stationery_services" (click)="closeDropdown()"><i class="i-Idea-2"></i> Services</a>
                <a routerLink="/pages/profile" (click)="closeDropdown()"><i class="i-Business-Man"></i> Profile</a>
                <!-- <a routerLink="/pages/sessions"><i class="i-Checked-User"></i> Sessions</a> -->
                <a routerLink="/pages/support" (click)="closeDropdown()"><i class="i-Ambulance"></i> Support</a>
            </div>              
          </div>
          <div class="col-md-4 p-4">
            <p
              class="text-primary text--cap border-bottom-primary d-inline-block"
            >
              Documentations
            </p>
            <ul class="links">
              <li><a >How to make payment</a></li>
              <li><a >How to make request for print</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Search bar -->
    <!-- <div class="search-bar">
      <input
        type="text"
        placeholder="Search"
        (focus)="searchService.searchOpen = true"
      />
      <i class="search-icon text-muted i-Magnifi-Glass1"></i>
    </div> -->
  </div>

  <div style="margin: auto"></div>

  <div class="header-part-right">
    <!-- <i
      class="i-Magnifi-Glass1 header-icon"
      role="button"
      (click)="searchService.searchOpen = true"
    ></i> -->
    <i
      fullScreenWindow
      class="i-Full-Screen header-icon d-none d-sm-inline-block"
      role="button"
    ></i>
    <div
      ngbDropdown
      [placement]="'bottom-right'"
      class="d-none d-sm-inline-block"
    >
      <i
        ngbDropdownToggle
        class="i-Gear text-muted header-icon"
        role="button"
      ></i>
      <div ngbDropdownMenu class="menu-icon-grid-dropdown">
        <div class="menu-icon-grid">
          <a routerLink="/dashboard/v2"><i class="i-Shop-4"></i> Home</a>
          <!-- <a routerLink="/dashboard/v2"><i class="i-Drop"></i> Apps</a> -->
          <!-- <a routerLink="/dashboard/v2"><i class="i-File-Clipboard-File--Text"></i> Forms</a> -->
          <!-- <a routerLink="/dashboard/v2"><i class="i-Checked-User"></i> Sessions</a> -->
          <a routerLink="/pages/support"><i class="i-Ambulance"></i> Support</a>
        </div>
      </div>
    </div>

    <div ngbDropdown [placement]="'bottom-right'" (click)="readAllNotification()">
      <div class="badge-top-container">
        <span class="badge badge-primary" *ngIf="notifications?.length > 0">{{
          notifications?.length
        }}</span>
        <i
          ngbDropdownToggle
          class="i-Bell text-muted header-icon"
          role="button"
        ></i>
      </div>
      <div
        ngbDropdownMenu
        class="notification-dropdown rtl-ps-none"
        perfectScrollbar
      >
        <div
          class="dropdown-item d-flex"
          *ngFor="let item of notifications"
          [routerLink]="['/pages/notifications']"
          (click)="readNotification(item.id)"
        >
          <div class="notification-icon">
            <i
              *ngIf="notifications?.subject === 100"
              class="i-Speach-Bubble-6 text-primary mr-1"
            ></i>
            <i
              *ngIf="notifications?.subject === 200"
              class="i-Receipt-3 text-success mr-1"
            ></i>

            <i
              *ngIf="notifications?.subject === 300"
              class="i-Receipt-3 text-danger mr-1"
            ></i>
          </div>
          <div class="notification-details flex-grow-1">
            <p class="m-0 d-flex align-items-center">
              <span *ngIf="item.subject ==100">Pending Request</span>
              <span *ngIf="item.subject ==200">Processed Request</span>
              <span *ngIf="item.subject ==300">Rejected Request</span>
              <span
                *ngIf="item.badge"
                class="badge badge-pill badge-{{ item.status }} ml-1 mr-1"
                >{{ item.badge }}</span
              >
              <span class="flex-grow-1"></span>
              <span class="text-small text-muted ml-auto">{{
                item.time | relativeTime
              }}</span>
            </p>
            <p class="text-small text-muted m-0">
              {{ item.message | excerpt : 30 }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      ngbDropdown
      [placement]="'bottom-right'"
      class="user col align-self-end"
    >
      <img
        src="./assets/images/faces/18.jpg"
        id="userDropdown"
        ngbDropdownToggle
        alt=""
      />

      <div ngbDropdownMenu aria-labelledby="userDropdown">
        <div class="dropdown-header">
          <i class="i-Lock-User mr-1"></i> {{ userDetails?.firstname }}
          {{ userDetails?.lastname }}
        </div>
        <div class="dropdown-header">
          <span *ngIf="userDetails?.role == 'user' || userDetails?.role == 'stationery'"></span>
          Balance Tsh {{ account ? account.amount_available  : 'N/A' }}<br>
          <span  *ngIf="account?.amount_held > 0">Amount Held ({{ account?.amount_held || 'N/A' }})</span>
        </div>

        <button class="dropdown-item"  routerLink="/pages/profile">Account settings</button>
        <button class="dropdown-item"  routerLink="/pages/payments" *ngIf="userDetails?.role == 'user' || userDetails?.role == 'stationery'">Billing history</button>
        <button class="dropdown-item" (click)="signout()">Sign out</button>
      </div>
    </div>
  </div>
</div>
