import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router  } from '@angular/router';
import { AuthService } from './auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {
  access_token: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService
  ) { }

//   canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): boolean {
//     if (this.authService.isAuthenticated()) {
//       // Check if the user has a valid and active token
//       return true;
//     } else {
//       // Navigate to the login page if the user is not authenticated
//       this.router.navigate(['/sessions/signin']);
//       return false;
//     }
//   }
// }

canActivate(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean {
  this.access_token = this.cookieService.get('access_token');
  if (this.authService.isAuthenticated() && !this.authService.isTokenExpired(this.access_token)) {
    // Check if the user has a valid and active token
    return true;
  } else {
    // Navigate to the login page if the user is not authenticated or token is expired
    this.router.navigate(['/sessions/signin']);
    return false;
  }
}

}
