<div class="app-footer">
	<div class="row">
		<div class="col-md-9">
			<p><strong>STATIONERY MTANDAO</strong></p>
			<p>We makes your stationery life easy.</p>
		</div>
	</div>
	<div class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
        <!-- <a class="btn btn-primary text-white btn-rounded mb-3 mb-sm-0 mr-0 mr-sm-3" 
        href="#" 
        target="_blank">Privacy Policy</a> -->
        <!-- <a class="btn btn-warning btn-rounded mb-3 mb-sm-0" 
        href="#" 
        target="_blank">Feedback</a> -->
        
		<span class="flex-grow-1"></span>
		<div class="d-flex align-items-center">
			<img class="logo" src="./assets/images/logo.png" alt="">
			<div>
				<p class="m-0">&copy; 2024 Stationery Mtandao</p>
				<p class="m-0">All rights reserved</p>
			</div>
		</div>
	</div>
</div>