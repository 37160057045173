import { Component, OnInit, ViewChild } from "@angular/core";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { SearchService } from "src/app/shared/services/search.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { ToastrService } from "ngx-toastr";
import { MainService } from "src/app/shared/services/main.service";
import { DatePipe } from '@angular/common';
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: "app-header-sidebar-compact",
  templateUrl: "./header-sidebar-compact.component.html",
  styleUrls: ["./header-sidebar-compact.component.scss"]
})
export class HeaderSidebarCompactComponent implements OnInit {
  notifications: any[];
  userDetails : any;
  account: any;
  @ViewChild('accountDropdown') accountDropdown: NgbDropdown;
  loading: boolean;

  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private auth: AuthService,
    private toastr: ToastrService,
    private api:MainService,
    private datePipe: DatePipe,
    private navigationService:NavigationService
  ) {
    this.getNotifications()
    // this.notifications = [
    //   {
    //     icon: "i-Speach-Bubble-6",
    //     title: "New message",
    //     badge: "3",
    //     text: "James: Hey! are you busy?",
    //     time: new Date(),
    //     status: "primary",
    //     link: "/chat"
    //   },
    //   {
    //     icon: "i-Receipt-3",
    //     title: "New order received",
    //     badge: "$4036",
    //     text: "1 Headphone, 3 iPhone x",
    //     time: new Date("11/11/2018"),
    //     status: "success",
    //     link: "/tables/full"
    //   },
    //   {
    //     icon: "i-Empty-Box",
    //     title: "Product out of stock",
    //     text: "Headphone E67, R98, XL90, Q77",
    //     time: new Date("11/10/2018"),
    //     status: "danger",
    //     link: "/tables/list"
    //   },
    //   {
    //     icon: "i-Data-Power",
    //     title: "Server up!",
    //     text: "Server rebooted successfully",
    //     time: new Date("11/08/2018"),
    //     status: "success",
    //     link: "/dashboard/v2"
    //   },
    //   {
    //     icon: "i-Data-Block",
    //     title: "Server down!",
    //     badge: "Resolved",
    //     text: "Region 1: Server crashed!",
    //     time: new Date("11/06/2018"),
    //     status: "danger",
    //     link: "/dashboard/v3"
    //   }
    // ];
  }

  ngOnInit() {this.getInfo(); this.getAccountDetails()}

  toggelSidebar() {
    const state = this.navService.sidebarState;
    state.sidenavOpen = !state.sidenavOpen;
    state.childnavOpen = !state.childnavOpen;
  }

  signout() {
    this.auth.signout();
  }

  getInfo(){
    this.userDetails = sessionStorage.getItem('userDetails')
		this.userDetails = this.userDetails? JSON.parse(this.userDetails): {};
    this.navigationService.publishNavigationChange(this.userDetails.role);
	}

  closeDropdown() {
    if (this.accountDropdown) {
      this.accountDropdown.close();
    }
  }

    getNotifications(){
      this.api.getNotifications().subscribe(
        (res) => {
          this.notifications = res
        },(error) => {
            this.toastr.error(` ${error.error.msg}!`, 'Failed', { timeOut: 30000 });
          }
        );
    }

  getAccountDetails(){
    this.loading = true
		this.api.getAccountDetails().subscribe(
      (res) => {
        this.account = res
        this.loading = false
			},(error) => {
        this.loading = false
				}
			);
	}

  readNotification(notificationId: number): void {
    this.api.readNotification(notificationId).subscribe(
      (res) => {
        
			},(error) => {
				}
			);
  }

  readAllNotification(): void {
    this.api.readAllNotification().subscribe(
      (res) => {
        
			},(error) => {
				}
			);
  }
}
