import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'relativeTime' })
export class RelativeTimePipe implements PipeTransform {
  transform(value: string) {
    const inputTime = moment.utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]", true);
    
    if (!inputTime.isValid()) {
      return 'Invalid Date';
    }

    const currentTime = moment.utc();
    const duration = moment.duration(currentTime.diff(inputTime));

    if (duration.asDays() > 1) {
      return Math.floor(duration.asDays()) + ' days ago';
    } else if (duration.asHours() > 1) {
      return Math.floor(duration.asHours()) + ' hours ago';
    } else {
      return Math.floor(duration.asSeconds()) + ' seconds ago';
    }
  }
}
