import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: "root",
})
export class AuthService {
   access_token:any;
  constructor(
    private store: LocalStoreService,
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    // this.checkAuth();
  }

  //Only for demo purpose
  authenticated = false;

  private apiUrl = "https://stationerymtandao.pythonanywhere.com/";


  // Set up the headers with the access token
  private get headers(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.cookieService.get('access_token')}`,
    });
  }

  setAuthenticated(value: boolean): void {
    this.authenticated = value;
  }

  // isAuthenticated(): boolean {
  //   const token = this.getAccessToken();

  //   // Check if the token exists and is not expired
  //   return !!token && !this.isTokenExpired(token);
  // }

  isAuthenticated(): boolean {
    const token = this.getAccessToken();

    // Check if the token exists and is not expired
    return !!token && !this.isTokenExpired(token)
    // if (token){
    //   return true
    // }else{
    //   false
    // }
  }

  isTokenExpired(token): boolean {

    if (token) {
      try {
        const decoded: any = jwtDecode(token);

        // Check if the token has an expiration date and if it's still valid
        if (decoded.exp && decoded.exp * 1000 > Date.now()) {
          // console.log("remain time to expire token",(decoded.exp && decoded.exp * 1000)-Date.now(),"now is",Date.now())
          return false; // Token is not expired
        } else {
          return true; // Token is expired
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        return true; // Treat decoding errors as expired tokens
      }
    }

    return true; // No token or an invalid token
  }


  getAccessToken() {
     this.access_token = this.cookieService.get('access_token');
    return this.access_token;
  }

  setAccessToken(accesstoken) {
    this.cookieService.set('access_token', accesstoken);
   return this.access_token;
 }

  // checkAuth() {
  //   this.authenticated = this.store.getItem("demo_login_status");
  //   return this.authenticated;
  // }

  getuser() {
    return of({});
  }

  signin(credentials) {
    this.authenticated = true;
    return this.http.post<any>(`${this.apiUrl}auth/login`, credentials);
    // return of({}).pipe(delay(1500));
  }
  
  signout() {
    this.authenticated = false;
    this.store.setItem("demo_login_status", false);
    this.router.navigateByUrl("/sessions/signin");
  }

  getData(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}user/getUserData`, {
      headers: this.headers,
    });
  }

  forgotPassword(formData: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}auth/forgotPassword`, formData);
  }

  sendVerificationCode(formData: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}auth/sendVerificationCode`, formData);
  }

  validateVc(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(
      `${this.apiUrl}auth/validateVerificationCode`,
      payload
    );
  }

  addUser(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(
      `${this.apiUrl}auth/addUser`,
      payload
    );
  }

  resetvalidateVc(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(
      `${this.apiUrl}auth/resetValidateVerificationCode`,
      payload
    );
  }

  updateLoginDetails(credentials) {
    this.authenticated = true;
    return this.http.post<any>(`${this.apiUrl}auth/updateDetails`, credentials,{
      headers: this.headers,
    });
    // return of({}).pipe(delay(1500));
  }

  // login(formData: any): Observable<any> {
  //   // Assuming your API expects a POST request with JSON payload
  //   return this.http.post<any>(`${this.apiUrl}login`, formData);
  // }
}
