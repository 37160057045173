import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class MainService {
  access_token: any;
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  //Only for demo purpose
  authenticated = true;

  private apiUrl = "https://stationerymtandao.pythonanywhere.com/";

  // Set up the headers with the access token
  private get headers(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.cookieService.get("access_token")}`,
    });
  }

  getAllUsers(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}user/getAll`, {
      headers: this.headers,
    });
  }

  getReferrals(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}user/getReferrals`, {
      headers: this.headers,
    });
  }

  getReferralsNo(id:number): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}user/getReferralsNo/${id}`, {
      headers: this.headers,
    });
  }


  getRequests(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}services/getRequests`, {
      headers: this.headers,
    });
  }

  getProblemSupports(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}services/getProblemSupport`, {
      headers: this.headers,
    });
  }

  getVerifications(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}services/getVerifications`, {
      headers: this.headers,
    });
  }

  getProcessedRequests(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}services/getProcessedRequests`, {
      headers: this.headers,
    });
  }
  getSales(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}stationery/getSales`, {
      headers: this.headers,
    });
  }

  getProducts(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}stationery/getProducts`, {
      headers: this.headers,
    });
  }

  postSale(payload:any): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.post<any>(`${this.apiUrl}stationery/addSale`, payload, {
      headers: this.headers,
    });
  }

  postProduct(payload:any): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.post<any>(`${this.apiUrl}stationery/addProduct`, payload, {
      headers: this.headers,
    });
  }

  postCategory(payload:any): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.post<any>(`${this.apiUrl}stationery/addCategory`, payload, {
      headers: this.headers,
    });
  }

  deleteCategory(id:number): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.delete<any>(`${this.apiUrl}stationery/deleteCategory/${id}`, {
      headers: this.headers,
    });
  }
  
  deleteProduct(id:number): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.delete<any>(`${this.apiUrl}stationery/deleteProduct/${id}`, {
      headers: this.headers,
    });
  }

  getCategories(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}stationery/getCategories`, {
      headers: this.headers,
    });
  }

  getServicesNames(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}stationery/getServicesNames`, {
      headers: this.headers,
    });
  }

  getMyServicesNames(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}stationery/getMyServicesNames`, {
      headers: this.headers,
    });
  }

  getAllStationery(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}stationery/getAll`, {
      headers: this.headers,
    });
  }
  
  getSalesData(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}stationery/getSalesData`, {
      headers: this.headers,
    });
  }


  getNotifications(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}notifications/getAll`, {
      headers: this.headers,
    });
  }

  readAllNotifications(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}notifications/readAll`, {
      headers: this.headers,
    });
  }

  getTotalSales(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}services/getTotalSales`, {
      headers: this.headers,
    });
  }

  getAccountDetails(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}payments/getAccountDetails`, {
      headers: this.headers,
    });
  }

  getTopCustomers(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}services/getTopCustomers`, {
      headers: this.headers,
    });
  }

  getMonthlySales(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}services/getMonthlySales`, {
      headers: this.headers,
    });
  }

  getLastMonthlySales(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}services/getLastMonthlySales`, {
      headers: this.headers,
    });
  }

  getWeeklySales(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}services/getWeeklySales`, {
      headers: this.headers,
    });
  }

  getMyTransactions(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}payments/getTransactions`, {
      headers: this.headers,
    });
  }

  getWithdrawalOrders(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}payments/getWithdrawalOrders`, {
      headers: this.headers,
    });
  }

  getWithdrawalPesapalOrders(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}payments/getWithdrawalPesapalOrders`, {
      headers: this.headers,
    });
  }

  getPesapalOrders(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}payments/getPesapalOrders`, {
      headers: this.headers,
    });
  }

  confirmPayed(order_id:any): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}payments/confirmPayed/${order_id}`, {
      headers: this.headers,
    });
  }

  getPesapalOrderStatus(order_id:any): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}payments/getPesapalOrderStatus/${order_id}`, {
      headers: this.headers,
    });
  }

  getLocations(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}stationery/getLocations`, {
      headers: this.headers,
    });
  }

  getAllLocations(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}stationery/getAllLocations`, {
      headers: this.headers,
    });
  }

  getMyStationeryServices(id: number): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}services/getAll/${id}`, {
      headers: this.headers,
    });
  }

  getPaymentHistory(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}payments/getPaymentHistory`, {
      headers: this.headers,
    });
  }

  readNotification(id:number): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}notifications/readNotification/${id}`, {
      headers: this.headers,
    });
  }

  readAllNotification(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}notifications/readAllNotification`, {
      headers: this.headers,
    });
  }

  readNotifications(): Observable<any> {
    // Assuming your API expects a GET request with JSON payload
    return this.http.get<any>(`${this.apiUrl}notifications/readAll`, {
      headers: this.headers,
    });
  }

  uploadFile(payload: FormData) {
    const uploadUrl = `${this.apiUrl}upload_pdf`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.cookieService.get("access_token")}`,
    });
    return this.http.post(uploadUrl, payload, { headers });
  }

  // deposit(payload: any): Observable<any> {
  //   // Assuming your API expects a POST request with JSON payload
  //   return this.http.post<any>(`${this.apiUrl}payments/submit_order`, payload, {
  //     headers: this.headers,
  //   });
  // }

  deposit(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}payments/addPayment`, payload, {
      headers: this.headers,
    });
  }

  depositPush(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}payments/submit_order`, payload, {
      headers: this.headers,
    });
  }

  withdrawal(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}payments/withdrawal`, payload, {
      headers: this.headers,
    });
  }

  approvePayment(id: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.get<any>(`${this.apiUrl}payments/approvePayment/${id}`, {
      headers: this.headers,
    });
  }

  callback_url(orderTrackingId:any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}payments/callback_url`, orderTrackingId, {
      headers: this.headers,
    });
  }

  activate(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}auth/activate`, payload, {
      headers: this.headers,
    });
  }

  deactivate(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}auth/deactivate`, payload, {
      headers: this.headers,
    });
  }

  activateStationery(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}stationery/activate`, payload, {
      headers: this.headers,
    });
  }

  deactivateStationery(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}stationery/deactivate`, payload, {
      headers: this.headers,
    });
  }
  activateService(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}stationery/activateService`, payload, {
      headers: this.headers,
    });
  }
 
  activateServiceName(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}stationery/activateServiceName`, payload, {
      headers: this.headers,
    });
  }

  deactivateServiceName(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}stationery/deactivateServiceName`, payload, {
      headers: this.headers,
    });
  }

  removeService(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}stationery/removeService`, payload, {
      headers: this.headers,
    });
  }
  

  addServiceName(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}stationery/addServiceName`, payload, {
      headers: this.headers,
    });
  }

  addService(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}stationery/addService`, payload, {
      headers: this.headers,
    });
  }

  addSupportProblem(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}services/addSupportProblem`, payload, {
      headers: this.headers,
    });
  }

  acceptRequest(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}services/accept`, payload, {
      headers: this.headers,
    });
  }

  rejectRequest(payload: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.post<any>(`${this.apiUrl}services/reject`, payload, {
      headers: this.headers,
    });
  }

  getDocument(id: any): Observable<any> {
    // Assuming your API expects a POST request with JSON payload
    return this.http.get<any>(`${this.apiUrl}services/getDocument/${id}`, {
      headers: this.headers,
    });
  }
}
