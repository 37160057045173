import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    selectedItem: IMenuItem;
    
    constructor() {
    }

    defaultMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'You can see overall data in your account from here',
            type: 'link',
            icon: 'i-Bar-Chart',
            // sub: [
            //     // { icon: 'i-Clock-3', name: 'Version 1', state: '/dashboard/v1', type: 'link' },
            //     { icon: 'i-Clock-4', name: 'Dashboard', state: '/dashboard/v2', type: 'link' },
            //     // { icon: 'i-Over-Time', name: 'Version 3', state: '/dashboard/v3', type: 'link' },
            //     // { icon: 'i-Clock', name: 'Version 4', state: '/dashboard/v4', type: 'link' },
            // ]
            state: '/dashboard/v2'
        },
     
        {
            name: 'Profile',
            description: 'Personal Informations/Details.',
            type: 'link',
            icon: 'i-Administrator',
            state:'/pages/profile'
        },
    ];

    stationeryMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'You can see overall data in your account from here',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/dashboard/v2'
        },
        {
            name: 'Pending Requests',
            description: 'Here you can view pending requests',
            type: 'link',
            icon: 'i-Repeat2',
            state: '/pages/requests',
        },
        {
            name: 'Processed Requests',
            description: 'Here you can view proccessed requests',
            type: 'link',
            icon: 'i-Memory-Card-2',
            state: '/pages/processed-requests',
        },
        {
            name: 'Services',
            description: 'Check And Manage Services from here.',
            type: 'link',
            icon: 'i-Idea-2',
            state:'/pages/stationery_services',
        },
        {
            name: 'Products And Purchases',
            description: 'Products And Purchases',
            type: 'link',
            icon: 'i-Clock-3',
            state:'/pages/products',
        },
        {
            name: 'Sales',
            description: 'Sales',
            type: 'link',
            icon: 'i-Money-2',
            state:'/pages/sales',
        },
        
        {
            name: 'Notifications',
            description: 'Personal Informations/Details.',
            type: 'link',
            icon: 'i-Mail-2',
            state:'/pages/notifications'

        },      
        {
            name: 'Profile',
            description: 'Personal Informations/Details.',
            type: 'link',
            icon: 'i-Administrator',
            state:'/pages/profile'
        },
    ];

    userMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'You can see overall data in your account from here',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/dashboard/v2'
        },
        {
            name: 'Map',
            description: 'Check Map then select the stationery where you want service.',
            type: 'link',
            icon: 'i-Map2',
            state:'/pages/map',
        },
        {
            name: 'Pending Requests',
            description: 'Here you can view pending requests',
            type: 'link',
            icon: 'i-Repeat2',
            state:'/pages/requests',
        },
        {
            name: 'Processed Requests',
            description: 'Here you can view proccessed requests',
            type: 'link',
            icon: 'i-Memory-Card-2',
            state: '/pages/processed-requests',
        },
        {
            name: 'Notifications',
            description: 'Personal Informations/Details.',
            type: 'link',
            icon: 'i-Mail-2',
            state:'/pages/notifications'
        },      
        {
            name: 'Profile',
            description: 'Personal Informations/Details.',
            type: 'link',
            icon: 'i-Administrator',
            state:'/pages/profile'
        },
    ];

    adminMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'You can see overall data in your account from here',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/dashboard/v2'
        },
        {   
            name: 'System Users',
            description: 'You can see overall system users in system',
            type: 'link',
            icon: 'i-Business-Mens',
            state: '/pages/users'
        },

        {
            name: 'Map',
            description: 'Check Map then select the stationery where you want service.',
            type: 'link',
            icon: 'i-Map2',
            state:'/pages/map',
        },
        {
            name: 'Stationery',
            description: 'Check And Manage Stationery from here.',
            type: 'link',
            icon: 'i-University1',
            state:'/pages/stationery',
        },
        {
            name: 'Services',
            description: 'Check And Manage Services from here.',
            type: 'link',
            icon: 'i-Idea-2',
            state:'/pages/stationery_services',
        },
        {
            name: 'Requests',
            description: 'Here you can view pending and proccessed requests',
            type: 'dropDown',
            icon: 'i-Repeat2',
            sub: [
                { icon: 'i-Repeat-3', name: 'Pending Requests', state: '/pages/requests', type: 'link' },
                // { icon: 'i-Full-View-Window', name: 'Processed Requests', state: '/tables/full', type: 'link' },
                // { icon: 'i-Code-Window', name: 'Paging', state: '/tables/paging', type: 'link' },
                { icon: 'i-Repeat2', name: 'Processed Requests', state: '/pages/processed-requests', type: 'link' },
            ]
        },
        {
            name: 'Calls',
            description: 'All Calls, Pending and Processsed Ones',
            type: 'link',
            icon: 'i-Mail-2',
            state:'/pages/calls'
        }, 
        {
            name: 'Refferals',
            description: 'All Referrals',
            type: 'link',
            icon: 'i-Find-User',
            state:'/pages/referral'
            // sub: [
            //     { icon: 'i-Gear', name: 'Settings', state: '/pages/profile', type: 'link' },
            //     { icon: 'i-Money-2', name: 'Payments', state: '/pages/profile', type: 'link' },
            //     // { icon: 'i-Find-User', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
            // ]
        },       
        {
            name: 'Profile',
            description: 'Personal Informations/Details.',
            type: 'link',
            icon: 'i-Administrator',
            state:'/pages/profile'
            // sub: [
            //     { icon: 'i-Gear', name: 'Settings', state: '/pages/profile', type: 'link' },
            //     { icon: 'i-Money-2', name: 'Payments', state: '/pages/profile', type: 'link' },
            //     // { icon: 'i-Find-User', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
            // ]
        },
    ];


    mainAgentMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'You can see overall data in your account from here',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/dashboard/v2'
        },
        {   
            name: 'System Users',
            description: 'You can see overall system users in system',
            type: 'link',
            icon: 'i-Business-Mens',
            state: '/pages/users'
        },
        // {
        //     name: 'Requests',
        //     description: 'Here you can view pending and proccessed requests',
        //     type: 'dropDown',
        //     icon: 'i-Repeat2',
        //     sub: [
        //         { icon: 'i-Repeat-3', name: 'Pending Requests', state: '/pages/requests', type: 'link' },
        //         // { icon: 'i-Full-View-Window', name: 'Processed Requests', state: '/tables/full', type: 'link' },
        //         // { icon: 'i-Code-Window', name: 'Paging', state: '/tables/paging', type: 'link' },
        //         { icon: 'i-Repeat2', name: 'Processed Requests', state: '/pages/processed-requests', type: 'link' },
        //     ]
        // },
        {
            name: 'Verification Codes',
            description: 'Check Verification Codes of users',
            type: 'link',
            icon: 'i-Telephone',
            state:'/pages/verifications',
        },
        {
            name: 'Calls',
            description: 'All Calls, Pending and Processsed Ones',
            type: 'link',
            icon: 'i-Mail-2',
            state:'/pages/calls'
        },      
        {
            name: 'Profile',
            description: 'Personal Informations/Details.',
            type: 'link',
            icon: 'i-Administrator',
            state:'/pages/profile'
        },
    ];



    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    publishNavigationChange(userType: string) {
        switch (userType) {
          case 'stationery':
            this.menuItems.next(this.stationeryMenu);
            break;
          case 'user':
            this.menuItems.next(this.userMenu);
            break;
          case 'admin':
            this.menuItems.next(this.adminMenu);
            break;
          case 'mainagent':
            this.menuItems.next(this.mainAgentMenu)
            break;
          default:
            this.menuItems.next(this.defaultMenu);
        }
    }
}
